import React from 'react';

const Footer = () => {
  return (
    <div className="footer">
      <a
        className='footer__pdf text-black-normal-f12-l16'
        href='https://respublikapark.com.ua/privacy-policy'
        target='_blank' rel="noreferrer"
      >
        Політика конфіденційності
      </a>

      <a
        className='footer__pdf text-black-normal-f12-l16'
        href='https://cms.respublikapark.com.ua/uploads/Pravila_TR_Cz_Respublika_Park_938d233bec_0354c2c935.pdf'
        target='_blank' rel="noreferrer"
      >
        Умови користування
      </a>

      <a
        className='footer__link text-black-normal-f12-l16'
        href='https://respublikapark.com.ua'
      >
        respublikapark.com.ua
      </a>
    </div>
  );
};

export default Footer;
