import {createContext, memo, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {excludeRoute, session} from "../helpers/helpers";
import {instanceFetch} from "../utils/instanceFetch";
import mainRoutes from "../helpers/mainRoutes";

export const AuthContext = createContext();

const ROUTES = {
  USER: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/user`)
};

const AuthProvider = ({children, history}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = session.get('token');

  const [currentContactStore, setCurrentContactStore] = useState({});
  
  useEffect(() => {
    if (user?.contactStores) {
      setCurrentContactStore(user?.contactStores?.find(({current}) => current));
    }
  }, [user?.contactStores])
  
  useEffect(() => {
    if (token) {
      instanceFetch.get(ROUTES.USER, null, token).then(user => setUser(user));
      setLoading(false);
    }
  }, [token]);

  const isSigninPages = excludeRoute.includes(history.location.pathname);
  const isHomePage = (history.location.pathname === '/' && !token);
  const mainRoutesPatnames = mainRoutes.map(({path}) => path).includes(history.location.pathname);

  useEffect(() => {
    if (isSigninPages || isHomePage || !mainRoutesPatnames) {
      setLoading(false);
    }
  }, [user, history, isSigninPages, isHomePage, mainRoutesPatnames]);

  return (
    <AuthContext.Provider value={{ user, setUser, loading, setLoading, token, setCurrentContactStore, currentContactStore }}>{children}</AuthContext.Provider>
  );
};

export default withRouter(memo(AuthProvider));
