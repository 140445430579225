import { Form } from 'antd';
import React from 'react';

const exceptionCategory = [
  '1348c89b-5ef9-41b4-aff6-c4d19a1a1d18', // Крадіжка
  '197db0f1-b1e0-4dd5-ba81-c536a4bc05d0', // Освітлення
  'be2de17e-c006-4fb0-ad5c-719670f63afb', // Клімат
  '8c88139c-e323-4c9a-8fed-591c1bc5e87a', // Матеріальні збитки приміщенню
  'a625a6dc-8cff-4070-b091-5e04963c75de', // Надзвичайна ситуація
  '0e956337-6221-481b-b816-8567104c9f41', // Пожежні системи
];

export const RequestIncident = ({
  requests,
  formContentItem,
  PrepareSelect,
  prepareSelectOptions,
  disabledForm,
}) => {
  const filterCategoriesJob = requests?.ServiceCategory?.filter(({ Id }) => {
    return exceptionCategory.includes(Id);
  });

  return (
    <>
      <Form.Item
        name="ServiceCategory"
        label="Категорія сервісу"
        rules={[{ required: true, message: 'Вкажіть категорію сервісу' }]}
      >
        <PrepareSelect
          options={prepareSelectOptions(filterCategoriesJob)}
          placeholder="Обрати"
          disabledForm={disabledForm}
        />
      </Form.Item>

      {formContentItem.Symptoms}
    </>
  );
};

export default RequestIncident;
