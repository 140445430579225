import React from 'react';
import RequestProvider from './Request.Provider';
import RequestCreate from './RequestCreate';

const RequestCreateContainer = () => {
  return (
    <RequestProvider>
      <RequestCreate />
    </RequestProvider>
  );
};

export default RequestCreateContainer;
