import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Table} from 'antd';
import Svg from '../../components/SVG/SVG';
import {session} from '../../helpers/helpers';
import {instanceFetch} from '../../utils/instanceFetch';
import moment from 'moment';
import {isEmpty} from 'lodash';
import {downloadFile} from '../../utils/urlImgToBlob';
import {useContactStore} from "../../hooks/useContactStore";

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    className: 'date',
  },
  {
    title: 'Номер рахунку',
    dataIndex: 'accountNumber',
    className: 'account-number center',
  },
  {
    title: 'Сума на сплату',
    dataIndex: 'amount',
    className: 'amount-sum center',
  },
  {
    title: 'Рахунок',
    dataIndex: 'score',
    className: 'score center',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    className: 'status center',
  },
];

const AccountTable = () => {
  const {currentContactStore} = useContactStore();
  const [counterparty, setCounterparty] = useState([]);

  const token = session.get('token');
  const ROUTES = {
    COUNTERPARTY: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/user/counterparty`),
  };

  useEffect(() => {
    instanceFetch
      .get(ROUTES.COUNTERPARTY, null, token)
      .then((res) => setCounterparty(res))
      .catch((error) => console.log(error));
  }, [currentContactStore, token]);

  const download = useCallback((id) => {
    const ROUTES = {
      FILE: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/files/${id}`),
    };
    return downloadFile(ROUTES.FILE, token);
  }, []);

  const prepareRequestData = useMemo(() => {
    return counterparty.map(({ startDate, amount, file, number, status }) => ({
      date: (
        <div className="f13-grey-dark">
          <div>{moment(startDate).format('YYYY-MM-DD')}</div>
          <div className="f13-grey">{moment(startDate).format('HH:mm')}</div>
        </div>
      ),
      accountNumber: <div className="f16-grey-dark">{number}</div>,
      amount: <div className="f16-black">{amount.toFixed(2)} грн</div>,
      score: !isEmpty(file) && (
        <div className="f16-black" onClick={() => download(file[0].id)}>
          Завантажити
          <Svg className="download" name="download" />
        </div>
      ),
      status: <div>{status}</div>,
    }));
  }, [counterparty]);

  return (
    <Table
      className="default-table account-table request-table"
      columns={columns}
      dataSource={prepareRequestData}
      locale={{ emptyText: 'Дані Відсутні' }}
    />
  );
};

export default AccountTable;
