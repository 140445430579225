import React, {useContext, useState} from 'react';
import { session } from '../../helpers/helpers';
import {Link, useHistory} from "react-router-dom";
import { AuthContext } from "../../Guard/AuthContext";
import { instanceFetch } from "../../utils/instanceFetch";
import Button from '../../components/Button/Button';
import InputText from '../../components/Input/InputText';
import Checkbox from '../../components/Checkbox/Checkbox';

const ROUTES = {
  LOGIN: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/login`)
};

const Signin = () => {
  const { setLoading } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [active, setActive] = useState(false);
  const { push } = useHistory();

  const login = (e) => {
    e.preventDefault();
    const eventTargetElements = Array.from(e.target.elements);

    const payload = eventTargetElements.reduce((acc, { value, name }) => {
      if (!!name) {
        acc[name] = value;
      }

      return acc;
    }, {});

    instanceFetch.post(ROUTES.LOGIN, payload).then(token => {
      session.set('token', token.plainTextToken);
      const tokenValue = token.plainTextToken;

      setLoading(true);

      if (tokenValue) {
        push('/');
        setLoading(false);
      }
    }).catch(() => setError(true));
  };

  return (
    <div className='signin'>
      <div className='signin__col signin__col_form'>
        <a href='/' className='signin__link'>
          <img src='/images/logo.svg' alt='logo'/>
        </a>

        <div className='signin__inner'>
          <div className='text-black-bold-f40-l48'>Увійти</div>
          <div className='text-grey-dark-normal-f16-l28'>Будь ласка, авторизуйтесь, щоб увійти до кабінету орендаря.</div>

          <form onSubmit={(e) => login(e)} className='signin-form form'>
            <InputText
              // value='olya.andriiash@gmail.com'
              label='Email'
              id='identifier'
            />

            <InputText
              type='password'
              // value='sJsGDuQfg2'
              label='Пароль'
              id='password'
            />

            <div className='signin-form__wrapper'>
              <Checkbox
                checked={active}
                onClick={() => setActive(!active)}
                label='Запам’ятати'
              />

              <Link to='/recovery-password' className='text-turquoise-normal-f16-l28'>Не пам’ятаю пароль</Link>
            </div>

            <Button
              type='submit'
              text='Увiйти'
              bg='pink'
            />
          </form>

          {error && <div className='text-red-normal-f16-l28'>Не правильний логін чи пароль</div>}

          <div className='text-grey-dark-normal-f16-l28'>Хочете орендувати площу в ТРЦ Respublika Park?</div>
          <a className='text-turquoise-normal-f16-l28' href='/'>Дізнайтесь як.</a>
        </div>
      </div>

      <div className='signin__col signin__col_banner' />
    </div>
  );
};

export default Signin;
