import {session} from "../helpers/helpers";
import {useCallback, useContext} from "react";
import {AuthContext} from "../Guard/AuthContext";
import {instanceFetch} from "../utils/instanceFetch";
import {useHistory} from "react-router-dom";

export const useContactStore = () => {
    const token = session.get('token');
  const location = useHistory();
    const {user, setUser, currentContactStore, setCurrentContactStore} = useContext(AuthContext);

    const userContactStores = user?.contactStores;

    const handleChange = useCallback((id) => {
        const newStore = userContactStores?.find(({crmId}) => id === crmId);
        setCurrentContactStore(newStore);
        instanceFetch.post(
            new URL(`${process.env.REACT_APP_API_URL}/api/b2b/user/set-contact-store/${newStore?.crmId}`),
            {},
            token)
            .then((res) => {
                setUser(res);
            })
          .then(() => {
            if (location?.location?.pathname.includes('/requests/') || location?.location?.pathname.includes('/edit-request/')) {
              location.push('/')
            }
          })
          .catch((error) => {
            console.log(error)
        });
    }, [token, currentContactStore]);

    const currentContactStoreOptions = userContactStores?.map(({crmId, name}) => ({value: crmId, text: name}));

    return {currentContactStoreOptions, currentContactStore, handleChange};
}