import React, { useCallback, useEffect, useState } from 'react'
import moment from "moment-timezone";
import locale from "antd/es/date-picker/locale/uk_UA";
import {DatePicker, Form} from "antd";
import { useHistory } from 'react-router-dom'
const {RangePicker} = DatePicker;

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

const RequestSuWorkField = ({clickedSubmit, currentCategoryValue, currentServiceCategory, form, SuStartWork, SuEndWork}) => {
  const location = useHistory();
  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
 
  const isAdminCategory = currentCategoryValue === 'd7982047-eef2-435d-bf58-758c818433e4';
  const isExploitationCategory = currentCategoryValue === 'f8b0f38b-60fd-42d9-b87d-39e704ed6d27';
  const adminCategoryStart22 = isAdminCategory && currentServiceCategory !== 'ddc7cc8b-2808-40e5-84a9-97ef0fc008fb'
  const isServiceCategoryMismatch = !isAdminCategory || (adminCategoryStart22);


  const requestToAdminCategory = isAdminCategory && !isServiceCategoryMismatch;
  const requestToExploitation = isExploitationCategory || isServiceCategoryMismatch && !adminCategoryStart22;

  const getDefaultStartTime = () => {
    const now = moment();
    const startOfDay = moment().startOf('day').add(22, 'hours');
    return now.isBefore(startOfDay) ? startOfDay : now;
  };
  const getDefaultEndTime = () => {
    return getDefaultStartTime().clone().add(1, 'hours');
  };
  const getDefaultStartTimeToExploitation = () => {
    const now = moment();
    const startOfDay = moment().startOf('day');
    const currentTimeMinutes = moment().minute();

    if (currentTimeMinutes > 0) {
      return now.add(1, 'hour');
    }

    return now.isBefore(startOfDay) ? startOfDay : now;
  };
  const getDefaultEndTimeToExploitation = () => {
    return getDefaultStartTimeToExploitation().clone().add(1, 'hours');
  };

  const startValueDefault = moment().hour() >= 16
    ? getDefaultStartTime(dates && dates[0]).add(1, 'day')
    : getDefaultStartTime(dates && dates[1]);

  const endValueDefault = startValueDefault.clone().add(1, 'hour');

  const startValueDefaultToExploitation = getDefaultStartTimeToExploitation(dates && dates[1]);

  const endValueDefaultToExploitation = startValueDefaultToExploitation.clone().add(1, 'hour');

  const [focused, setFocused] = useState(false);

  const disabledDateTwoDays = useCallback((current) => {
    let startValueToUse = startValueDefault;
    let endValueToUse = endValueDefault;

    const maxSelectableDate = moment().add(9, 'day');
    if (!dates || (!dates[0] && !dates[1])) {
      return current.isAfter(maxSelectableDate, 'day');
    }

    else if (dates[0] && dates[1]) {
      const minSelectableDate = dates[1].clone().subtract(2, 'day');
      return current.isBefore(minSelectableDate, 'day') || current.isAfter(dates[1].clone().add(2, 'day'));
    }

    else if (dates[0]) {
      const maxSelectableDate = dates[0].clone().add(2, 'day');

      return current.isBefore(dates[0].clone().subtract(2, 'day')) || current.isAfter(maxSelectableDate, 'day');
    }

    else if (dates[1]) {
      const minSelectableDate = dates[1].clone().subtract(2, 'day');
      return current.isBefore(minSelectableDate, 'day') || current.isAfter(dates[1].clone().add(2, 'day'));
    }

    const tooLate = current.isAfter(startValueToUse.clone().add(2, 'day'));
    const tooEarly = current.isBefore(endValueToUse);

    return tooLate || tooEarly;
  }, [dates, form]);

  useEffect(()=> {
    if(requestToAdminCategory) {
      if (dates && dates[0] &&  dates[1]) {
        const minSelectableDate = dates[1].clone().subtract(2, 'day').subtract(1, 'hours');

        if (minSelectableDate < startValueDefault) {
          form.setFieldsValue({ SuWork: [dates[0], dates[1]] });
        }
        else if (dates[0].date() < minSelectableDate.date() ) {
          form.setFieldsValue({ SuWork: [minSelectableDate, dates[1]] });
        }
        else if (minSelectableDate.hour() > dates[0].hour() && dates[0].date() > minSelectableDate.date()) {
          form.setFieldsValue({ SuWork: [dates[0], dates[1]] });
        }
      }
    }
  },[dates, form, currentCategoryValue, currentServiceCategory])

  const prevCategoryRef = React.useRef(null);
  const onOpenChange = useCallback((open) => {
    if (open) {
      setFocused(true);

      if (currentServiceCategory !== prevCategoryRef.current && requestToAdminCategory) {
        form.setFieldsValue({ SuWork: [startValueDefault, endValueDefault] });
        setDates([null, null]);
      }

      if (currentServiceCategory === undefined && !dates) {
        form.setFieldsValue({ SuWork: [startValueDefault, endValueDefault] });
        setDates([null, null]);
      }

      if (isServiceCategoryMismatch) {
        form.setFieldsValue({ SuWork: [startValueDefaultToExploitation, endValueDefaultToExploitation] });
        setDates([null, null]);
      }

      if (adminCategoryStart22 && isServiceCategoryMismatch) {
        form.setFieldsValue({ SuWork: [startValueDefault, endValueDefault] });
        setDates([null, null]);
      }
    } else {
      setFocused(false);
    }
    prevCategoryRef.current = currentServiceCategory;
  }, [dates, currentServiceCategory, form, startValueDefault, endValueDefault]);


  useEffect(()=> {
    if (requestToAdminCategory) {
      form.resetFields(['SuWork'])
    }
  }, [currentServiceCategory, requestToAdminCategory])

  const disabledDate = useCallback((current) => {
    const currentTimeHours = moment().hour();
    if (current < moment().startOf('day')) {
      return true;
    }
    if (current > moment().add(9, 'days') && currentServiceCategory !== 'ddc7cc8b-2808-40e5-84a9-97ef0fc008fb') {
      return true;
    }

    if (current > moment().add(9, 'days')) {
      return true;
    }

    if (currentTimeHours >= 16 && current.format("DD") === moment().format("DD")) {
      return true;
    }

    else if (requestToAdminCategory) {
      return disabledDateTwoDays(current)
    }
    else if (requestToExploitation) {
      return false
    }

  }, [dates]);

  const disabledDateToExploitation = useCallback((current) => {
    if (current < moment().startOf('day')) {
      return true;
    }
    if (current > moment().add(9, 'days')) {
      return true;
    }

    return false;
  }, []);

  const disabledRangeTime = (_, type) => {
    const today = moment().startOf('day');
    const currentTimeHours = moment().hour();
    const tomorrow = moment().add(1, 'day').startOf('day');

    if (type === 'start') {
      if (today.isSame(_, 'day') && currentTimeHours <= 16) {
        return {
          disabledHours: () => range(0, 22),
        };
      }
      else if ((today.isAfter(_, 'day') || tomorrow.isSame(_, 'day')) && currentTimeHours >= 16) {
        return {
          disabledHours: () => range(0, 22),
        };
      }
    } else if (type === 'end') {
      if (today.isSame(_, 'day')) {
        if (currentTimeHours <= 16) {
          return {
            disabledHours: () => range(0, 22),
          };
        } else {
          return {
            disabledHours: () => range(0, 22),
          };
        }
      } else if (tomorrow.isSame(_, 'day') && currentTimeHours >= 16) {
        return {
          disabledHours: () => range(0, 22),
        };
      }
    }

    return {
      disabledHours: () => [],
    };
  };

  const disabledRangeTimeToExploitation = (_, type) => {
    const currentTimeHours = moment().hour();
    const currentTimeMinutes = moment().minute();
    const today = moment().startOf('day');

    if (today.isSame(_, 'day')) {
      return {
        disabledHours: () => {
          const disabledHours = [];
          for (let i = 0; i < currentTimeHours; i++) {
            disabledHours.push(i);
          }
          if (currentTimeMinutes > 0) {
            disabledHours.push(currentTimeHours);
          }
          return disabledHours;
        }
      };
    }
    return {
      disabledHours: () => [],
    };
  }
  
  return (
    <Form.Item
      name="SuWork"
      label="Дата/період"
      rules={[{required: true, message: 'Оберіть дату робіт'}]}
    >
    <RangePicker
      allowClear={false}
      inputReadOnly
      locale={locale}
      placeholder={['Початок робіт', 'Кінець робіт']}
      disabledDate={requestToExploitation ? disabledDateToExploitation : disabledDate}
      disabledTime={requestToExploitation ? disabledRangeTimeToExploitation : disabledRangeTime}
      disabled={clickedSubmit}
      showTime={{
        hideDisabledOptions: true,
        defaultValue: requestToExploitation
          ? [
            dates && getDefaultStartTimeToExploitation(dates[0]),
            dates && getDefaultEndTimeToExploitation(dates[1]),
          ]
          : [
            dates && getDefaultStartTime(dates[0]),
            dates && getDefaultEndTime(dates[1]),
          ],
      }}
      format="DD-MM-YYYY HH:00"
      value={dates || value}
      onCalendarChange={(val) => {
        setDates(val);
      }}
      onChange={(val) => {
        setValue(val);
      }}
      onOpenChange={onOpenChange}
      changeOnBlur
      defaultValue={location.location.pathname.slice(1) !== 'create-request' && [moment(SuStartWork), moment(SuEndWork)]}
    /></Form.Item>
  )
};

export default RequestSuWorkField
