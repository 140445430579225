import { Form, Modal, Select } from 'antd';
import cx from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button/Button';
import RequestProvider, { useRequestContext } from './Request.Provider';

moment.tz.setDefault('Europe/Kiev');

const RequestEditContent = () => {
  const { id } = useParams();
  const location = useHistory();
  const { push } = useHistory();
  
  const {
    availableRequestFields,
    request,
    form,
    clickedSubmit,
    formContent,
    handleClickForm,
    onValuesFormChange,
    successModal,
    errorModal,
    setErrorModal,
    setSuccessModal,
  } = useRequestContext();
  
  useEffect(() => {
    if (successModal) {
      setTimeout(() => {
        setSuccessModal(false);
        location.push('/');
      }, 1000);
    }
    if (errorModal) {
      setTimeout(() => {
        setErrorModal(false);
        push(`/requests/${id}`);
      }, 4000);
    }
  }, [successModal, errorModal, location, push, id])
  
  return (
    <RequestProvider id={id}>
      <div className="request-page-create boxes">
        <div className="box create-request">
          <div className="text-black-medium-f22-l30">Редагування запиту</div>

          <div className="text-grey-dark-normal-f16-l28">
            Будь ласка, заповніть усі необхідні дані для створення заявки.
          </div>
          <div className="create-request__container field-grey-straight-border">
            <div className="request-ediatable">
              <div className="request-ediatable-id">
                <div className="request-ediatable-id-label">ID звернення:</div>
                <div className="request-ediatable-id-value">{request?.Number}</div>
              </div>
              <div className="request-ediatable-content">
                <Form
                  onValuesChange={(e) => onValuesFormChange(e)}
                  form={form}
                  className={cx('request-ediatable-form', { disabled: clickedSubmit })}
                >
                  <Form.Item name="Category" label="Тип запиту">
                    <Select
                      disabled
                      placeholder={availableRequestFields?.Type?.displayValue}
                      value={availableRequestFields?.Type?.value}
                    ></Select>
                  </Form.Item>

                  {availableRequestFields?.Category && (
                    <Form.Item name="Category" label="Категорія сервісу">
                      <Select
                        disabled
                        placeholder={availableRequestFields?.Category?.displayValue}
                        value={availableRequestFields?.Category?.value}
                      ></Select>
                    </Form.Item>
                  )}

                  {formContent[availableRequestFields?.Type?.value]}

                  <Form.Item>
                    <Button
                      onClick={handleClickForm}
                      type="submit"
                      text="Надіслати"
                      bg="pink"
                      disabled={clickedSubmit}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
          className="success-modal"
          visible={errorModal}
          footer={null}
          onCancel={() => {setErrorModal(false)}}
      >
        <div className="success-modal__wrapper">
          <div className="text-black-medium-f22-l30">Помилка</div>
          <div className="text-grey-dark-normal-f16-l28">
            Вибачте, статус заявки було змінено, ви не можете редагувати заявку з цим статусом.
            Вас буде перенаправлено на сторінку заявки.
          </div>
        </div>
      </Modal>
      <Modal
        className="success-modal"
        visible={successModal}
        footer={null}
        onCancel={() => setSuccessModal(false)}
      >
        <div className="success-modal__wrapper">
          <div className="text-black-medium-f22-l30">Дякуємо</div>
          <div className="text-grey-dark-normal-f16-l28">
            Ми прийняли ваш запит та зв’яжемось із вами найближчим часом.
          </div>
        </div>
      </Modal>
    </RequestProvider>
  );
};

export default RequestEditContent;
