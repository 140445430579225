import React from 'react';
import Svg from "../SVG/SVG";
import cx from "classnames";

const LabelIcon = ({ bg, color, icon }) => {
  const labelIconClassName = cx(
    'label-icon',
    `${bg}-bg`,
    `${color}-color`
  );

  return (
    <div className={labelIconClassName}>
      <Svg name={icon} />
    </div>
  );
};

export default LabelIcon;
