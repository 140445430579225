import React from 'react';
import cx from 'classnames';
import Svg from "../SVG/SVG";

const Checkbox = ({ id, label, disabled, checked, onClick, labelWithLink }) => {
  const checkboxClassName = cx(
    'field__checkbox',
    disabled && 'disabled',
    checked && !disabled && 'checked'
  );

  return (
    <div className='field field_row' onClick={onClick}>
      <button type="button" className={checkboxClassName} id={id}>
        {checked && <Svg name='check' />}
      </button>

      {label && (
        <span className="field__label" htmlFor={id}>
          {label}
        </span>
      )}

      {labelWithLink && (
        <span className="field__label text-turquoise-normal-f16-l28" htmlFor={id} dangerouslySetInnerHTML={labelWithLink}></span>
      )}
    </div>
  );
};

export default Checkbox;
