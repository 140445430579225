import React, {memo, useContext} from 'react';
import {Space, Spin} from 'antd';
import {Redirect, withRouter} from 'react-router-dom';
import {AuthContext} from './AuthContext';
import {excludeRoute, session} from "../helpers/helpers";
import mainRoutes from "../helpers/mainRoutes";

const Guard = ({history, children}) => {
  const token = session.get('token');
  const {loading, user, currentContactStore} = useContext(AuthContext);

  const mainRoutesPatnames = mainRoutes.some(({path}) => {
    const [currentPath] = path.replace(/^\//, '').split('/');
    const [mathRoute] = history.location.pathname.replace(/^\//, '').split('/');

    if (!user) return currentPath === mathRoute;
    if ((currentContactStore?.hasOwnProperty(currentPath) && currentContactStore[currentPath] === false)
      || (currentPath === 'commodity-circulation' && currentContactStore['commodity'] === false))
      return false;

    return currentPath === mathRoute
  });

  if (loading && token) {
    return (
      <Space className='loading' size="middle">
        <Spin size="large"/>
      </Space>
    );
  }

  if (mainRoutesPatnames) {
    if (excludeRoute.includes(history.location.pathname) || token) {
      if (currentContactStore && (currentContactStore[history.location.pathname.replace(/^\//, '')] === false)) {
        return <Redirect to='/'/>;
      }
      return children;
    }
  }

  return token ? <Redirect to='/'/> : <Redirect to='/signin'/>;
};

export default withRouter(memo(Guard));