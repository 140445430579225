import React from "react";

const RequestMarketing = ({ formContentItem }) => {
  return (
    <>
      {formContentItem["SuWork"]}
      {formContentItem["SuLoadingZone"]}
      {formContentItem["SuCarNumber"]}
      {formContentItem["MarketingFile"]}
      {formContentItem["Symptoms"]}
    </>
  );
};

export default RequestMarketing;
