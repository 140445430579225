import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import Guard from "./Guard/Guard";
import mainRoutes from "./helpers/mainRoutes";

const RenderRoutes = ({ history }) => {
  const mainRoutesPatnames = mainRoutes.some(({path}) => {
    const [currentPath] = path.replace(/^\//,'').split('/');
    const [mathRoute] = history.location.pathname.replace(/^\//,'').split('/');

    return currentPath === mathRoute
  });

  return <>
    {mainRoutes.map((router) => {
        if (router.routes) {
          const Child = router.component;
          return (
            <Switch key={router.path}>
              <Route
                {...router}
                component={(props) => (
                  <Guard {...props}>
                    <Child/>
                  </Guard>
                )}
              />
              {router.routes.map((childrenRouter) => {
                const Child = childrenRouter.component;
                return (
                  <Route
                    key={router.path + childrenRouter.path}
                    path={router.path + childrenRouter.path}
                    exact={childrenRouter.exact}
                    component={(props) => (
                      <Guard {...props}>
                        <Child/>
                      </Guard>
                    )}
                  />
                );
              })}
            </Switch>
          );
        }

        const Child = router.component;

        return (
          <>
            <Route
              key={router.path}
              exact={router.exact}
              path={router.path}
              component={(props) => (
                <Guard {...props}>
                  <Child/>
                </Guard>
              )}
            />
          </>
        )
      })
    }

    {!mainRoutesPatnames && <Route exact path='*' render={() => <Redirect to='/' />} />}
  </>
};

export default withRouter((RenderRoutes));
