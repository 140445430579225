import React from 'react';
import { useParams } from 'react-router-dom';
import RequestProvider from './Request.Provider';
import RequestEditContent from './RequestEditContent';

const RequestEditEntry = () => {
  const { id } = useParams();

  return (
    <RequestProvider id={id}>
      <RequestEditContent />
    </RequestProvider>
  );
};

export default RequestEditEntry;
