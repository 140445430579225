import React, {useEffect, useMemo, useState} from 'react';
import {Table} from 'antd';
import {session} from '../../helpers/helpers';
import {instanceFetch} from '../../utils/instanceFetch';
import moment from 'moment';
import {isEmpty} from "lodash";
import {useContactStore} from "../../hooks/useContactStore";

const columns = [
  {
    title: 'Дата',
    dataIndex: 'date',
    className: 'date',
  },
  {
    title: 'Номер рахунку',
    dataIndex: 'appointment',
    className: 'account-number',
  },
  {
    title: 'Призначення платежу',
    dataIndex: 'purposeOfPayment',
    className: 'purpose-of-payment',
  },
  {
    title: 'Сума на сплату',
    dataIndex: 'amountSum',
    className: 'amount-sum center',
  },
];

const AccrualTable = () => {
  const {currentContactStore} = useContactStore();
  const [payments, setPayments] = useState([]);

  const token = session.get('token');
  const ROUTES = {
    PAYMENTS: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/user/payments`),
  };

  useEffect(() => {
    instanceFetch
      .get(ROUTES.PAYMENTS, null, token)
      .then((res) => setPayments(res))
      .catch((error) => console.log(error));
  }, [currentContactStore, token]);

  const prepareRequestData = useMemo(() => {
    return payments.map(({ dateOfHolding, number, sum, purposeOfPayment }) => ({
      date: (
        <div className="f13-grey-dark">
          <div>{moment(dateOfHolding).format('YYYY-MM-DD')}</div>
          <div className="f13-grey">{moment(dateOfHolding).format('HH:mm')}</div>
        </div>
      ),
      appointment: <div className="f16-grey-dark">{number}</div>,
      purposeOfPayment: <div className="f16-grey-dark">{purposeOfPayment.length > 0 ? purposeOfPayment : '-'}</div>,
      amountSum: <div className="f16-black">{sum.toFixed(2)} грн</div>,
    }));
  }, [payments]);

  if (isEmpty(payments)) return null;

  return (
    <Table
      className="default-table account-table request-table"
      columns={columns}
      dataSource={prepareRequestData}
      locale={{ emptyText: 'Дані Відсутні' }}
    />
  );
};

export default AccrualTable;
