import React, {useState} from 'react';
import cx from 'classnames';

const InputText = (props) => {
  const {
    id,
    label,
    className,
    placeholder,
    value,
    type,
    error,
    onChange,
    disabled = false
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  const onChangeHandler = (e) => {
    setCurrentValue(e.target.value);
    onChange && onChange(e);
  };

  return (
    <div className={cx('field field_col', className)}>
      <label
        className='field__label'
        htmlFor={id}
      >
        {label}
      </label>

      <input
        type={type}
        className={cx('field__input', {'disabled': disabled})}
        placeholder={placeholder}
        id={id}
        name={id}
        value={currentValue}
        onChange={onChangeHandler}
        disabled={disabled}
      />

      {error && <div className='field__error'>{error}</div>}
    </div>
  );
};

export default InputText;
