import {Form, Modal} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import Button from '../../components/Button/Button';
import Svg from '../../components/SVG/SVG';
import {session} from '../../helpers/helpers';
import {instanceFetch} from '../../utils/instanceFetch';
import {downloadFile} from '../../utils/urlImgToBlob';
import {PrepareSelect, prepareSelectOptions} from './RequestFormContentItem';

export const LoadingZone = ({ currentLoadingZone, requests, clickedSubmit, defaultValue }) => {
  const token = session.get('token');
  const [imageLoadingZoneSmall, setImageLoadingZoneSmall] = useState(undefined);
  const [imageLoadingZoneFull, setImageLoadingZoneFull] = useState(undefined);
  const [loadingZoneModal, setLoadingZoneModal] = useState(false);

  const loadingZoneFileURL = `${process.env.REACT_APP_API_URL}/api/b2b/requests/zone/${currentLoadingZone}`;

  useEffect(() => {
    if (currentLoadingZone) {
      instanceFetch.get(loadingZoneFileURL, null, token).then((response) => {
        if (response?.preview) {
          setImageLoadingZoneSmall(response.cmsDomain + response?.preview?.formats?.medium?.url);
          setImageLoadingZoneFull(response.cmsDomain + response?.preview?.url);
        } else {
          setImageLoadingZoneSmall(undefined);
          setImageLoadingZoneFull(undefined);
        }
      });
    }
  }, [currentLoadingZone, loadingZoneFileURL, token]);

  const handleClickLoadingZone = () => {
    setLoadingZoneModal(true);
  };

  const renderSuLoadingZoneItems = useMemo(() => {
    if (!requests?.SuLoadingZone) return null;
    const SuLoadingZoneArray = Object.values(requests?.SuLoadingZone);
    const sortingSuLoadingZoneArray = SuLoadingZoneArray.sort((a, b) => {
      if (a.SerialNumber < b.SerialNumber) {
        return -1;
      }
      if (a.SerialNumber > b.SerialNumber) {
        return 1;
      }
      return 0;
    });

    return (
      <PrepareSelect
        defaultValue={defaultValue}
        options={prepareSelectOptions(sortingSuLoadingZoneArray)}
        placeholder="Вкажіть зону завантаження"
        disabledForm={clickedSubmit}
      />
    );
  }, [requests?.SuLoadingZone]);

  return (
    <>
      <Form.Item
        rules={[{ required: true, message: 'Оберіть зону завантаження' }]}
        name="SuLoadingZone"
        label="Зона завантаження"
      >
        {renderSuLoadingZoneItems}
      </Form.Item>

      {imageLoadingZoneSmall && (
        <img
          className="loading-zone-img"
          src={imageLoadingZoneSmall}
          alt="road_map"
          title="Відкрийте щоб завантажити"
          onClick={handleClickLoadingZone}
        />
      )}

      <Modal
        className="modal-type-green-close"
        visible={loadingZoneModal}
        footer={null}
        onCancel={() => setLoadingZoneModal(false)}
        closeIcon={<Svg name="close-modal" />}
      >
        <div className="modal-type-green-close__wrapper">
          <img className="loading-zone-img-modal" src={imageLoadingZoneFull} alt="road_map" />
          <Button
            type="submit"
            text="Завантажити"
            bg="pink"
            disabled={clickedSubmit}
            onClick={() => downloadFile(imageLoadingZoneFull, token)}
          />
        </div>
      </Modal>
    </>
  );
};
