import React, {useContext, useEffect, useMemo, useState} from 'react';
import InputText from '../../components/Input/InputText';
import Button from '../../components/Button/Button';
import {instanceFetch} from '../../utils/instanceFetch';
import {session} from '../../helpers/helpers';
import {isEmpty} from 'lodash';
import {AuthContext} from '../../Guard/AuthContext';

const Profile = () => {
  const { user, setUser } = useContext(AuthContext);
  const token = session.get('token');
  const [errorsInPatch, setErrorsInPatch] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);

  const ROUTES = {
    USER: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/user`),
  };

  useEffect(() => {
    instanceFetch
      .get(ROUTES.USER, null, token)
      .then((user) => setUser(user))
      .catch((error) => console.log(error));
  }, [errorsInPatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const eventTargetElements = Array.from(e.target.elements);
    const payload = eventTargetElements.reduce((acc, { value, name }) => {
      if (!!name) {
        acc[name] = value;
      }

      return acc;
    }, {});

    instanceFetch
      .patch(ROUTES.USER, payload, token)
      .then((user) => {
        setUser(user);
        setIsSuccess(true);
        setErrorsInPatch([])
      })
      .catch((error) => {
        setErrorsInPatch(error?.errors);
      });
  };

  const renderUserData = useMemo(() => {
    if (isEmpty(user)) return null;
    return (
      <div className="profile">
        <div className="boxes">
          <div className="profile-header">
            <img className="profile-header__avatar" src="/images/profile-avatar.png" alt="avatar" />

            <div className="profile-header__title">{user.fullName || user.username}</div>
          </div>

          <div className="boxes__col left">
            <div className="box profile-box">
              <div className="profile-box-header">
                <div className="profile-box-header__title text-blue-medium-f22-l30">
                  Дані користувача
                </div>
              </div>

              <form onSubmit={handleSubmit} className="form field-grey-straight-border">
                <InputText
                  id="fullName"
                  value={user?.fullName}
                  label="ПІБ:"
                  error={errorsInPatch['fullName'] && errorsInPatch['fullName'][0]}
                  disabled
                />
                <InputText
                  id="email"
                  value={user?.email}
                  label="Email:"
                  error={errorsInPatch['email'] && errorsInPatch['email'][0]}
                  disabled
                />
                <InputText
                  id="phone"
                  value={user?.phone}
                  label="Телефон:"
                  error={errorsInPatch['phone'] && errorsInPatch['phone'][0]}
                />
                <InputText
                  id="password"
                  label="Пароль:"
                  error={errorsInPatch['password'] && errorsInPatch['password'][0]}
                />

                <Button type="submit" text="Зберегти" bg="turquoise" block />

                {isSuccess && <div className="text-green-normal-f16-l28 ">Дані профілю були успішно змінені</div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }, [user]);

  return renderUserData;
};

export default Profile;
