import React from 'react';
import cx from 'classnames';
import Svg from "../SVG/SVG";

const Button = (props) => {
  const {
    text,
    block,
    type = 'button',
    disabled,
    className,
    onClick,
    children,
    bg,
    svg,
    ...otherProps
  } = props;

  const buttonClassName = cx(
    'btn',
    `${bg}-bg`,
    block && 'block',
    disabled && 'disabled',
    className
  );

  return (
    <button
      onClick={onClick}
      type={type}
      className={buttonClassName}
      disabled={disabled}
      {...otherProps}
    >
      {text && <span className="btn__text">{text}</span>}
      {svg && <Svg name={svg} /> }
    </button>
  );
};

export default Button;
