import React from 'react';

const Banner = () => {
  return (
    <div className='banner'>
      <div className="banner__inner">
        <div className="text-white-bold-f40-l48">Вітаємо!</div>
        <div className="text-white-medium-f22-l30">Раді допомогти у вирішенні щоденних задач орендарів ТРЦ Respublika Park.</div>
      </div>
    </div>
  );
};

export default Banner;
