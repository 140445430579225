import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import RequestTable from './RequestTable';

const RequestList = () => {
  return (
    <div className="request-page-list">
      <div className="request-list boxes">
        <div className="box request-item-header">
          <div className="request-item-header-title">
            <div className="text-black-bold-f19-l33">Запити</div>
            <div className="text-grey-normal-f16-l28">
              Тут зберігаються усі запити компанії та статус виконання.
            </div>
          </div>
          <Link to="/create-request">
            <Button text="Створити новий запит" bg="pink" svg="create" />
          </Link>
        </div>
        <div className="box request-list-content">
          <RequestTable />
        </div>
      </div>
    </div>
  );
};

export default RequestList;
