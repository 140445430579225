import { Form, Input, Modal, Select } from 'antd';
import cx from 'classnames';
import moment from 'moment-timezone';
import 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom'
import Button from '../../components/Button/Button';
import { RequestContext } from '../../helpers/requestContext';
import { useRequestContext } from './Request.Provider';

moment.tz.setDefault('Europe/Kiev');

const { Option } = Select;

const RequestCreate = () => {
  const {
    form,
    formContent,
    formContentItem,
    clickedSubmit,
    handleClickForm,
    onValuesFormChange,
    references,
    referencesCategory,
    formUpdate,
    successModal,
    setSuccessModal,
  } = useRequestContext();
  

  const location = useHistory();

  const [currentCategoryValue, setCurrentCategoryValue] = useState(location.location.search.slice(1));
  const selectCategoryValue = currentCategoryValue ? referencesCategory?.find((item) => item.Id === currentCategoryValue)?.Name : "Обрати";
 
  useEffect(() => {
    if (referencesCategory && currentCategoryValue) {
      return form.setFieldsValue({
        Category: currentCategoryValue,
      });
    }
  }, [referencesCategory]);
  const handleCategoryRequest = (e) => {
    setCurrentCategoryValue(e);
  };
  
  useEffect(() => {
    if (successModal) {
      setTimeout(() => {
        setSuccessModal(false);
        location.push('/');
      }, 1000);
    }
  }, [successModal, location, setSuccessModal])
  
  return (
    <div className="request-page-create boxes">
      <div className="box create-request">
        <div className="text-black-medium-f22-l30">Створення запиту</div>
        <div className="text-grey-dark-normal-f16-l28">
          Будь ласка, заповніть усі необхідні дані для створення заявки.
        </div>
        <div className="create-request__container field-grey-straight-border">
          <Form
            onValuesChange={(e) => onValuesFormChange(e)}
            form={form}
            className={cx('create-request__form', { disabled: clickedSubmit })}
          >
            <div className="create-request__col">
              <Form.Item
                name="Category"
                label="Категорія запиту"
                rules={[{ required: true, message: 'Оберіть категорію запиту' }]}
              >
                <Select
                  onChange={handleCategoryRequest}
                  loading={!referencesCategory?.length}
                  disabled={clickedSubmit || location.location.search.slice(1).length > 0}
                  placeholder={selectCategoryValue}
                >
                  {referencesCategory?.map(({ Id, Name }) => (
                    <Option key={Id} value={Id}>
                      {Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="Subject"
                label="Тема запиту"
                rules={[{ required: true, message: 'Опишіть тему запиту' }]}
              >
                <Input
                  // onChange={(e) => setCurrentSubject(e.target.value)}
                  placeholder="Опишіть тему запиту"
                  disabled={clickedSubmit}
                />
              </Form.Item>
            </div>
            <div className="create-request__col">
              {currentCategoryValue && (
                <>
                  {formContent[currentCategoryValue]}

                  {formContentItem.Agreement}
                  <Form.Item>
                    <Button
                      onClick={handleClickForm}
                      type="submit"
                      text="Надіслати"
                      bg="pink"
                      disabled={clickedSubmit}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </Form>
        </div>

        <Modal
          className="success-modal"
          visible={successModal}
          footer={null}
          onCancel={() => setSuccessModal(false)}
        >
          <div className="success-modal__wrapper">
            <div className="text-black-medium-f22-l30">Дякуємо</div>
            <div className="text-grey-dark-normal-f16-l28">
              Ми прийняли ваш запит та зв’яжемось із вами найближчим часом.
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default RequestCreate;
