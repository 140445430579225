import sprite from '../../assets/sprite/sprite.svg';
import cx from 'classnames';

const Svg = ({ name, className }) => {
  const svgClassName = cx('icon', className);

  return (
    <svg className={svgClassName}>
      <use href={`${sprite}#${name}`} />
    </svg>
  );
};

export default Svg;
