import { Form, Upload } from 'antd';
import React, { useState } from 'react';

export const MarketingFileUpload = ({ disabled }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [error, setErrors] = useState([]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const newErrors = [];

    const allowedFormats = ['.doc', '.docx', '.pdf', '.jpeg', '.jpg', '.png'];
    const fileExtension = file.name.split('.').pop();
    const isJpgOrPng = allowedFormats.includes(`.${fileExtension}`);
    if (!isJpgOrPng) {
      newErrors.push('Можливо завантажити лише jpg, png, pdf, doc!');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      newErrors.push(' Розмір файлу має бути менше 10MB!');
    }
    setErrors(newErrors);
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      setErrors([]);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  return (
    <Form.Item
      name="attach_file_1"
      label="Завантажити файл (jpg, png, pdf, doc)"
      rules={[{ message: error }]}
    >
      <Upload
        disabled={disabled}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        className="commodity-form__upload"
        maxCount={1}
      >
        Виберіть файл
      </Upload>
    </Form.Item>
  );
};
