import React, {useMemo, useState} from 'react';
import './assets/styles/styles.scss';
import {Router} from "react-router-dom";
import AppLayout from "./AppLayout";
import AuthProvider from "./Guard/AuthContext";
import { history } from "./utils/history.utils";
import {RequestContext} from "./helpers/requestContext";


const App = () => {
  const [createRequestFromDashboard, setCreateRequestFromDashboard] = useState(false);
  const value = useMemo(
    () => ({ createRequestFromDashboard, setCreateRequestFromDashboard }),
    [createRequestFromDashboard]
  );
  return (
    <Router history={history}>
      <AuthProvider>
        <RequestContext.Provider value={value}>
          <AppLayout />
        </RequestContext.Provider>
      </AuthProvider>
    </Router>
  );
};

export default App;
