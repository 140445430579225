import React, { useState } from 'react';
import { Tabs } from 'antd';
import CommodityDaily from './CommodityDaily';
import CommodityMonthly from './CommodityMonthly';
const { TabPane } = Tabs;

const tabNames = {
  tradeDaily: 'Товарообіг добовий',
  tradeMonthly: 'Товарообіг місячний',
  electricity: 'Показники лічильника'
}

const Commodity = () => {
  const [activeKey, setActiveKey] = useState('tradeDaily');

  const onChange = (key) => {
    setActiveKey(key);
  };
  return (
    <div className="commodity boxes">
      <div className="box commodity__header">
        <div className="box__title-inner">
          <div className="text-purple-dark-bold-f24-l34">{tabNames[activeKey]}</div>
        </div>
      </div>

      <Tabs className="tabs" defaultActiveKey="tradeDaily" onChange={onChange}>
        <TabPane key="tradeDaily" tab="Товарообіг добовий">
          <div className="commodity-form-max-wrapper">
            <div className="box commodity__content"> {/*TODO: add 'disabled' class if disabled*/}
              <CommodityDaily activeKey={activeKey} disabled={false} />
            </div>
          </div>
        </TabPane>
        <TabPane key="tradeMonthly" tab="Товарообіг місячний">
          <div className="box commodity__content">
            <CommodityMonthly activeKey={activeKey} />
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Commodity;
