import React from 'react';
import { Tabs } from "antd";
import AccountsTable from "./AccountsTable";
import AccrualTable from "./AccrualTable";
const { TabPane } = Tabs;

const Accounts = () => {
  return (
    <div className="request-page-list">
      <div className="accounts-page boxes">
        <div className="box account">
          <div className='box__title-inner'>
            <div className="text-black-bold-f19-l33">Рахунки</div>
            <div className="text-grey-dark-normal-f16-l28">
              Тут зберігаються усі рахунки компанії та їх статус виконання.
            </div>
          </div>

          <Tabs className='tabs' defaultActiveKey="1">
            <TabPane tab="Рахунки" key="1">
              <AccountsTable />
            </TabPane>
            <TabPane tab="Платежі" key="2">
              <AccrualTable />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
