import React, {useCallback, useEffect, useState} from 'react';
import {Table} from 'antd';
import {useHistory} from "react-router-dom";
import Svg from "../../components/SVG/SVG";
import {session} from "../../helpers/helpers";
import {instanceFetch} from "../../utils/instanceFetch";
import {useContactStore} from "../../hooks/useContactStore";

const columns = [
  {
    title: 'ID запиту',
    dataIndex: 'number',
    className: 'id',
  },
  {
    title: 'Категорія запиту',
    dataIndex: 'category',
    className: 'category',
  },
  {
    title: 'Тема запиту',
    dataIndex: 'subject',
    className: 'type',
  },
  {
    title: 'Статус виконання',
    dataIndex: 'status',
    className: 'status',
  },
  {
    title: 'Детальніше про запит',
    dataIndex: 'more',
    className: 'more',
  },
];

const ROUTES = {
  REQUESTS: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests`)
};

const RequestTable = () => {
  const [requests, setRequests] = useState([]);
  const { push } = useHistory();
  const {currentContactStore} = useContactStore();
  const token = session.get('token');

  const handleClick = useCallback((id) => {
    push(`/requests/${id}`);
  }, [push]);

  useEffect(() => {
    instanceFetch.get(ROUTES.REQUESTS, null, token).then(requests => setRequests(requests));
  }, [currentContactStore, token]);

  const prepareRequestData = requests.map(({ Number, Id, Category, Subject, Status }) => ({
    id: Id,
    number: <span>{Number}</span>,
    category: Category.displayValue,
    subject: Subject,
    status: Status.displayValue,
    more: <Svg name="see-more" />
  }));

  return (
    <Table
      className="default-table request-table"
      columns={columns}
      dataSource={prepareRequestData}
      locale={{ emptyText: 'Дані Відсутні' }}
      onRow={(record) => {
        return {
          onClick: () => handleClick(record.id)
        };
      }}
    />
  );
};

export default RequestTable;
