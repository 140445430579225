import React, {useMemo, useState} from 'react';
import Button from '../../components/Button/Button';
import InputText from '../../components/Input/InputText';
import { session } from '../../helpers/helpers';
import { useHistory, useLocation } from 'react-router-dom';
import { instanceFetch } from '../../utils/instanceFetch';

const ChangePassword = () => {
  const token = session.get('token');
  const [errorsInPost, setErrorsInPost] = useState([]);
  const [isSuccess, setIsSuccess] = useState('');
  const { push } = useHistory();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();
  const getQuery = query.get('code');

  const ROUTES = {
    PASSWORD: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/reset-password`),
  };

  const onChange = (e) => {
    setErrorsInPost([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const eventTargetElements = Array.from(e.target.elements);
    const payload = eventTargetElements.reduce((acc, { value, name }) => {
      if (!!name) {
        acc[name] = value;
      }
      if (!!getQuery) {
        return { ...acc, code: getQuery };
      }

      return acc;
    }, {});

    instanceFetch
      .post(ROUTES.PASSWORD, payload, token)
      .then((response) => {
        setErrorsInPost([]);
        setIsSuccess(response.message);
        push('/signin');
      })
      .catch((err) => {
        setErrorsInPost(err.errors);
      });
  };

  const renderForm = useMemo(() => {
    return (
      <form onSubmit={handleSubmit} className="form recovery-password-form">
        <InputText
          type='password'
          label="Новий пароль"
          id="password"
          onChange={onChange}
          error={errorsInPost['password'] && errorsInPost['password'][0]}
        />
        <InputText
          type='password'
          label="Новий пароль ще раз"
          id="passwordConfirmation"
          onChange={onChange}
          error={errorsInPost['passwordConfirmation'] && errorsInPost['passwordConfirmation'][0]}
        />
        <Button type="submit" text="Встановити" bg="pink" className="block" />

        {isSuccess && <div className="text-green-normal-f16-l28 ">{isSuccess}</div>}
      </form>
    )
  }, [errorsInPost, isSuccess]);

  return (
    <div className="signin">
      <div className="signin__col signin__col_form">
        <a href="/" className="signin__link">
          <img src="/images/logo.svg" alt="logo" />
        </a>

        <div className="signin__inner">
          <div className="text-black-bold-f40-l48">Пароль скинуто!</div>
          <div className="text-grey-dark-normal-f16-l28">
            Будь ласка, встановіть новий пароль для входу в кабінет орендаря.
          </div>

          {renderForm}

        </div>
      </div>

      <div className="signin__col signin__col_banner" />
    </div>
  );
};

export default ChangePassword;
