import {Form} from 'antd';
import {isEmpty} from 'lodash';
import moment from 'moment-timezone';
import {createContext, useContext, useEffect, useState} from 'react';
import {convertBase64, session} from '../../helpers/helpers';
import {instanceFetch} from '../../utils/instanceFetch';
import RequestAdministration from './RequestAdministration';
import RequestComplaint from './RequestComplaint';
import RequestFormContentItem, {PrepareSelect, prepareSelectOptions,} from './RequestFormContentItem';
import RequestIncident from './RequestIncident';
import RequestJob from './RequestJob';
import RequestMarketing from './RequestMarketing';
import RequestOthers from './RequestOthers';
import RequestSKD from './RequestSKD';

moment.tz.setDefault('Europe/Kiev');

const RequestContext = createContext({});
export const useRequestContext = () => {
  return useContext(RequestContext);
};

const aviableReferences = [
  'd7982047-eef2-435d-bf58-758c818433e4',
  'f8b0f38b-60fd-42d9-b87d-39e704ed6d27',
  '2a92dbea-2b8c-4a63-b3f6-ea4135f981d7',
]
const RequestProvider = ({ children, id }) => {
  const token = session.get('token');
  const [request, setRequest] = useState({});
  const [formUpdate, setFormUpdate] = useState(false);
  const [requests, setRequests] = useState([]);
  const [references, setReferences] = useState([]);
  const [referencesCategory, setReferencesCategory] = useState([]);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [errorsForm, setErrorsForm] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [form] = Form.useForm();
  const [formEdited, setFormEdited] = useState(false);
  const [sentValues, setSentValues] = useState(null);
  
  useEffect(() => {
    if (!isEmpty(references?.length)) return null;
    instanceFetch
      .get(new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests/references`), null, token)
      .then((references) => {
        const filtredReferences = references?.Category?.filter((item) => {
          return aviableReferences.includes(item.Id);
        });
        setReferences(references);
        setReferencesCategory(filtredReferences);
      });
  }, []);
  
  useEffect(() => {
    if (!isEmpty(requests?.length)) return null;
    instanceFetch
      .get(new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests`), null, token)
      .then((requests) => setRequests(requests));
  }, []);
  
  useEffect(() => {
    if (!id) return null;
    instanceFetch
      .get(new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests/${id}`), null, token)
      .then((request) => {
        setRequest(request);
      });
  }, [id, token]);

  const checkRequestStatus = async () => {
    if (!availableRequestFields?.Id) return true;

    const request = await instanceFetch
      .get(new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests/${id}`), null, token);

    if (request?.Status?.displayValue !== 'Нове') {
      setErrorModal(true);
      return false;
    }
    return true;
  };


  const handleClickForm = async () => {
    if (!isEmpty(errorsForm)) return null;
    setClickedSubmit(true);
    const isStatusValid = await checkRequestStatus();
    if (!isStatusValid) return null;
    const formData = await form.getFieldsValue();
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== undefined && value !== null && value !== '')
    );
    filteredFormData.requestType = 'case';
    const {SuWork, attach_file_1, ...restFormData} = filteredFormData;
    
    const suWorkStart =
      SuWork && moment(SuWork[0].set('minutes', 0).set('seconds', 0)).format().split('+')[0];
    const suWorkEnd =
      SuWork && moment(SuWork[1].set('minutes', 0).set('seconds', 0)).format().split('+')[0];
    
    let payload = { ...restFormData };
    
    if (SuWork) {
      payload.SuStartWork = suWorkStart;
      payload.SuEndWork = suWorkEnd;
    }
    
    if (attach_file_1) {
      const attachFile1 = {
        attach_file_1: await convertBase64(attach_file_1.file.originFileObj),
        attach_file_1_name: attach_file_1.file.name,
      };
      
      payload = { ...payload, ...attachFile1 };
    }
    
    let fetchRequest;
    
    if (availableRequestFields?.Id) {
      const payload = Object.keys(filteredFormData).reduce((acc, key) => {
        if (key === 'Category') {
          acc[key] = availableRequestFields['Type'].value;
        } else if (key === 'SuWork') {
          const suWorkStart =
            (filteredFormData['SuWork'] && moment(filteredFormData['SuWork'][0]).set('minutes', 0).set('seconds', 0).format().split('+')[0])
            || availableRequestFields['SuStartWork'];
          const suWorkEnd =
            (filteredFormData['SuWork'] && moment(filteredFormData['SuWork'][1]).set('minutes', 0).set('seconds', 0).format().split('+')[0])
            || availableRequestFields['SuEndWork'];
          
          acc['SuStartWork'] = suWorkStart;
          acc['SuEndWork'] = suWorkEnd;
        } else if (key !== 'Category') {
          if (filteredFormData[key] !== undefined && filteredFormData[key] !== null) {
            acc[key] = filteredFormData[key];
          } else if (availableRequestFields[key] && typeof availableRequestFields[key] === 'object' && 'value' in availableRequestFields[key]) {
            acc[key] = availableRequestFields[key].value;
          } else {
            acc[key] = filteredFormData[key] || availableRequestFields[key];
          }
        }
        return acc;
      }, {});
      
      const fileUpload = attach_file_1 && {
        attach_file_1: await convertBase64(attach_file_1.file.originFileObj),
        attach_file_1_name: attach_file_1.file.name,
      };
      
      const mergedPayload = { ...payload,fileUpload};
      
      fetchRequest = instanceFetch.patch(
        new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests/${id}`),
        { ...mergedPayload },
        token
      );
    } else {
      fetchRequest = instanceFetch.post(
        new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests`),
        payload,
        token
      );
    }
    
    setSentValues(payload);
    
    fetchRequest.then(() => {
      form.resetFields();
      setSuccessModal(true);
      setClickedSubmit(false);
    });
  };
  
  const onValuesFormChange = (e) => {
    form.setFieldsValue(e);
    setFormEdited(true);
    if (!id) {
      form
        .validateFields()
        .then((values) => console.log(values))
        .catch((error) => setErrorsForm(error.errorFields));
    }
  };
  
  
  const availableRequestFields = {
    Id: request?.Number,
    Type: request?.Category,
    Category: request?.ServiceCategory,
    SuContractorType: request?.SuContractorType,
    SuStartWork: request?.SuStartWork,
    SuEndWork: request?.SuEndWork,
    SuServiceSubCategory: request?.SuServiceSubCategory,
    RespNightWork: request?.RespNightWork,
    SuClaimDepartment: request?.SuClaimDepartment,
    SuTenantResonsible: request?.SuTenantResonsible,
    SuTelNumberTenant: request?.SuTelNumberTenant,
    RespContractorTenantResonsible: request?.RespContractorTenantResonsible,
    RespContractorTelNumberTenant: request?.RespContractorTelNumberTenant,
    RespContractorName: request?.RespContractorName,
    SuLoadingZone: request?.SuLoadingZone,
    SuCarNumber: request?.SuCarNumber,
    Symptoms: request?.Symptoms,
    SuWorkDescription: request?.SuWorkDescription,
    MarketingFile: request?.Attachments || null,
  };
  
  useEffect(() => {
  if (formEdited && sentValues) {
    form.setFieldsValue(sentValues);
  } else if (Object.values(availableRequestFields).every(value => value !== undefined) && !formEdited) {
    const fields = Object.entries(availableRequestFields).reduce((acc, [key, value]) => {
      if (value && typeof value === 'object' && 'value' in value) {
        acc[key] = value.name;
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    form.setFieldsValue(fields);
  }
}, [availableRequestFields, form, formEdited, sentValues]);
  
  const formContentItem = RequestFormContentItem({
    form,
    availableRequestFields,
    references,
    clickedSubmit,
  });
  
  const currentServiceCategory = availableRequestFields?.Category?.value;
  
  const requestServiceList = [
    'SuWork',
    'ServiceCategory',
    'SuServiceSubCategory',
    'SuPremise',
    'Symptoms',
  ];
  
  if (!request || !requests) return null;
  
  const formContent = {
    // Запрос до адміністрації
    'd7982047-eef2-435d-bf58-758c818433e4': (
      <RequestAdministration
        form={form}
        requests={references}
        formUpdate={formUpdate}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
        currentServiceCategory={currentServiceCategory || form.getFieldValue('ServiceCategory')}
      />
    ),
    // Запрос на работы
    'f8b0f38b-60fd-42d9-b87d-39e704ed6d27': (
      <RequestJob
        form={form}
        requests={references}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
      />
    ),
    // Инцидент
    '1b0bc159-150a-e111-a31b-00155d04c01d': (
      <RequestIncident
        requests={references}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
      />
    ),
    // Жалоба
    'f03c5eed-1406-4f2e-a548-318eb70ab0a8': (
      <RequestComplaint
        requests={references}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
      />
    ),
    // Другое
    '2df70c10-3d3b-4936-bbb4-620747cd7da2': (
      <RequestOthers
        form={form}
        requests={references}
        formUpdate={formUpdate}
        formContentItem={formContentItem}
        PrepareSelect={PrepareSelect}
        prepareSelectOptions={prepareSelectOptions}
        disabledForm={clickedSubmit}
      />
    ),
    // Запрос на обслуживание
    '1c0bc159-150a-e111-a31b-00155d04c01d': (
      <>{requestServiceList.map((name) => formContentItem[name])}</>
    ),
    // Видача карти СКД
    '2a92dbea-2b8c-4a63-b3f6-ea4135f981d7': <RequestSKD formContentItem={formContentItem} />,
    
    // Запит на маркетинг
    '0378d494-d47b-4f3a-9c9a-2c95658f6a90': <RequestMarketing formContentItem={formContentItem} />,
  };
  
  const nonEmptyFields = Object.entries(availableRequestFields)
    .filter(([key, value]) => value !== null && value !== '')
  
  const renderAviableFieldsToEdit = (formPossibleFields) => {
    return nonEmptyFields.map(([key, value]) => {
      switch (key) {
        case 'SuContractorType':
          return formPossibleFields['SuContractorType'];
        case 'SuStartWork' && 'SuEndWork':
          return formContentItem.SuWork(
            availableRequestFields['SuStartWork'],
            availableRequestFields['SuEndWork'],
          );
        case 'SuServiceSubCategory':
          return formPossibleFields['SuServiceSubCategory'];
        case 'RespNightWork':
          return formPossibleFields['RespNightWork'];
        case 'SuClaimDepartment':
          return formContentItem.SuClaimDepartment;
        case 'SuTenantResonsible':
          return formContentItem.SuTenantResonsible;
        case 'SuTelNumberTenant':
          return formContentItem.SuTelNumberTenant;
        case 'RespContractorTenantResonsible':
          return formPossibleFields['RespContractorTenantResonsible'];
        case 'RespContractorTelNumberTenant':
          return formPossibleFields['RespContractorTelNumberTenant'];
        case 'RespContractorName':
          return formPossibleFields['RespContractorName'];
        case 'SuLoadingZone':
          return formContentItem.SuLoadingZone;
        case 'SuCarNumber':
          return formContentItem.SuCarNumber;
        case 'Symptoms':
          return formContentItem.Symptoms;
        case 'SuWorkDescription':
          return formContentItem.SuWorkDescription;
        case 'MarketingFile':
          return formContentItem.MarketingFile;
        default:
          return null;
      }
    });
  }
  
  const value = {
    availableRequestFields,
    request,
    requests,
    form,
    formUpdate,
    formContent,
    formContentItem,
    clickedSubmit,
    setClickedSubmit,
    handleClickForm,
    onValuesFormChange,
    successModal,
    setSuccessModal,
    errorModal,
    setErrorModal,
    references,
    referencesCategory,
    renderAviableFieldsToEdit,
  };
  
  return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>;
};
export default RequestProvider;
