import {Form, Input} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react';
import {useRequestContext} from './Request.Provider';

const exceptionCategory = [
  'ddc7cc8b-2808-40e5-84a9-97ef0fc008fb', // Нічні роботи
  '5f72e68a-8bbb-4660-b442-e36df955db2e', // Переміщення товару
  '5369e6b8-4f16-45b3-8114-bdeeb2ca4969', // Маркетингові роботи
  '88a699c2-b2de-4bde-9ffd-c038a9a174f9', // Тимчасовий вхід
];

const workServiceCategory = 'ddc7cc8b-2808-40e5-84a9-97ef0fc008fb';
const movingGoodsServiceCategory = '5f72e68a-8bbb-4660-b442-e36df955db2e';
const marketingServiceCategory = '5369e6b8-4f16-45b3-8114-bdeeb2ca4969';
const temporaryEntryServiceCategory = '88a699c2-b2de-4bde-9ffd-c038a9a174f9';
const nightWorkServiceCategory = 'ddc7cc8b-2808-40e5-84a9-97ef0fc008fb';
const deliveryOfGoodsSubServiceCategory = 'e44c424b-10eb-450c-bf1b-e8a129591f3b';
const exportOfGoodsSubServiceCategory = '1b20c855-002d-437e-8e9e-465b77fdad89';

export const nightsWorksServiceSubCategory = '52c95acd-1f21-46cc-88e9-32cf06c1ddc7';
export const dailiesWorksServiceSubCategory = '6d5c6d49-843f-43a6-a22d-ad3164f734a0';
export const dayWorksServiceSubCategory = '667a0ffb-d792-4ce1-9c69-3fe6a4122fd3'; // Суточні роботи

export const RequestCreateContainer = (props) => {
  const [filteredSubServiceCategory, setFiltredSubServiceCategory] = useState([]);
  const {
    formContentItem,
    requests,
    PrepareSelect,
    prepareSelectOptions,
    disabledForm,
    currentServiceCategory,
  } = props;
  
   const { availableRequestFields, renderAviableFieldsToEdit } = useRequestContext();
   
  const filterServiceCategory = requests?.ServiceCategory?.filter(({ Id }) => {
    return exceptionCategory.includes(Id);
  });

  useEffect(() => {
    const exceptionMovingGoods = [
      deliveryOfGoodsSubServiceCategory,
      exportOfGoodsSubServiceCategory,
    ];
    const exceptionWork = [
      nightsWorksServiceSubCategory,
      dailiesWorksServiceSubCategory,
      dayWorksServiceSubCategory,
    ];

    if (currentServiceCategory === workServiceCategory) {
      const worksOptions = requests.SuServiceSubCategory?.filter(({ Id }) =>
        exceptionWork.includes(Id)
      );

      return setFiltredSubServiceCategory(worksOptions);
    }

    if (currentServiceCategory === movingGoodsServiceCategory) {
      const movingGoodsOptions = requests.SuServiceSubCategory?.filter(({ Id }) =>
        exceptionMovingGoods.includes(Id)
      );

      return setFiltredSubServiceCategory(movingGoodsOptions);
    }

    return setFiltredSubServiceCategory([]);
  }, [currentServiceCategory]);

  const formItemWithAnotherLabel = useCallback(
    (name, label, placeholder, required = false, isTextArea = false) => {
      return (
        <Form.Item name={name} label={label} rules={[{ required: required, message: placeholder }]}>
          {isTextArea ? (
            <TextArea placeholder={placeholder} disabled={disabledForm} />
          ) : (
            <Input placeholder={placeholder} disabled={disabledForm} />
          )}
        </Form.Item>
      );
    },
    []
  );

  const formPossibleFields = {
    RespNightWork: (
      <Form.Item
        name="RespNightWork"
        label="Виконавці робіт"
        rules={[{ required: true, message: 'Вкажіть виконавців робіт' }]}
      >
        <Input placeholder="Вкажіть виконавців робіт" disabled={disabledForm} />
      </Form.Item>
    ),
    SuServiceSubCategory: (
      <Form.Item
        name="SuServiceSubCategory"
        label="Підкатегорія сервісу"
        rules={[{ required: true, message: 'Вкажіть підкатегорію сервісу' }]}
      >
        <PrepareSelect
          defaultValue={availableRequestFields?.SuServiceSubCategory?.value}
          options={prepareSelectOptions(filteredSubServiceCategory)}
          placeholder="Обрати"
          disabledForm={disabledForm}
        />
      </Form.Item>
    ),
  };

 

  const isEditable = availableRequestFields?.Category?.value;
  

  return (
    <>
      {!isEditable && (
        <Form.Item
          name="ServiceCategory"
          label="Категорія сервісу"
          rules={[{ required: true, message: 'Вкажіть категорію сервісу' }]}
        >
          <PrepareSelect
            options={prepareSelectOptions(filterServiceCategory)}
            placeholder="Обрати"
            disabledForm={disabledForm}
          />
        </Form.Item>
      )}

      {currentServiceCategory === workServiceCategory && (
        <>
          {formContentItem.SuWork(moment(), moment().add(+1,'hours'))}
          {formPossibleFields['SuServiceSubCategory']}
          {formPossibleFields['RespNightWork']}
          {formContentItem.SuTenantResonsible}
          {formContentItem.SuTelNumberTenant}
          {formContentItem.SuLoadingZone}
          {formContentItem.SuCarNumber}
          {formContentItem.MarketingFile}
          {formContentItem.Symptoms}
          {formContentItem.SuWorkDescription}
        </>
      )}

      {currentServiceCategory === movingGoodsServiceCategory && (
        <>
          {formContentItem.SuWork(moment(), moment().add(+1,'hours'))}
          {formPossibleFields['SuServiceSubCategory']}
          {formContentItem.SuTenantResonsible}
          {formContentItem.SuTelNumberTenant}
          {formContentItem.SuLoadingZone}
          {formContentItem.SuCarNumber}
          <Form.Item name="RespNightWork" label="Виконавці робіт">
            <Input placeholder="Вкажіть виконавців робіт" disabled={disabledForm} />
          </Form.Item>
          {formItemWithAnotherLabel(
            'SuWorkDescription',
            'Кількість товару',
            'Вкажіть кількість товару'
          )}
        </>
      )}

      {currentServiceCategory === marketingServiceCategory && (
        <>
          {formContentItem.SuWork(moment(), moment().add(+1,'hours'))}
          {formContentItem.SuTenantResonsible}
          {formContentItem.SuTelNumberTenant}
          {formContentItem.SuLoadingZone}
          {formContentItem.SuCarNumber}
          {formContentItem.MarketingFile}
          {formContentItem.RespNightWork}
          {formContentItem.SuWorkDescription}
          {formContentItem.Symptoms}
        </>
      )}

      {currentServiceCategory === temporaryEntryServiceCategory && (
        <>
          {formContentItem.SuWork(moment(), moment().add(+1,'hours'))}
          {formContentItem.SuTenantResonsible}
          {formContentItem.SuTelNumberTenant}
          {formItemWithAnotherLabel(
            'Symptoms',
            'Доступ на вхід для',
            'Вкажіть прізвище, ім’я',
            true
          )}
          {formItemWithAnotherLabel(
            'SuWorkDescription',
            'Мета візиту',
            'Вкажіть мету візиту',
            true
          )}
        </>
      )}
    </>
  );
};

export default RequestCreateContainer;
