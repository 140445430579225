import React, {useEffect, useState} from "react";
import {Modal, Spin} from "antd";
import Svg from "../../components/SVG/SVG";
import {getBlobFromUrl, getDataFromBlob} from "../../utils/urlImgToBlob";
import {session} from "../../helpers/helpers";

const RequestItemAttachments = ({request}) => {
  const token = session.get('token');
  const ROUTES = {
    ATTACHMENT: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests/attachments/${request?.Attachments?.[0].id}`)
  };
  const [loading, setLoading] = useState(false);
  const [blobContent, setBlobContent] = useState(null);
  const [attachmentFileModal, setAttachmentFileModal] = useState(false)
  const [imageBlobType, setImageBlobType] = useState(false)
  const attachmentName = request?.Attachments?.[0]?.name

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (request?.Attachments?.[0]?.id) {
          setLoading(true);
          const blob = await getBlobFromUrl(ROUTES.ATTACHMENT, token);
          const data = await getDataFromBlob(blob, attachmentName);
          if (blob.type === 'image/jpeg' || blob.type === 'image/png') {
            setImageBlobType(true)
          }
          setBlobContent(data)
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [request?.Attachments?.[0]?.id]);

  const handleClickAttachmentFile = () => {
    setAttachmentFileModal(true);
  }

  return (<div className="request-info-item">
    <div className="text-black-normal-f12-l17">Файл</div>
    <div className="text-black-normal-f16-l28">
      <div className="request-attachment-file">
        {loading ? <Spin/> : imageBlobType ? (<>
          <img
            className="request-attachment-img"
            src={blobContent}
            alt={attachmentName}
            onClick={handleClickAttachmentFile}
          />

          <Modal
            className='modal-type-green-close attachment-file-modal'
            visible={attachmentFileModal}
            footer={null}
            onCancel={() => setAttachmentFileModal(false)}
            closeIcon={<Svg name='close-modal'/>}
          >
            <div className="modal-type-green-close__wrapper">
              <img className='request-attachment-img' src={blobContent} alt={attachmentName}/>
            </div>
          </Modal>
        </>) : <Svg className="download" name="download"/>}
      </div>
    </div>
  </div>)
};

export default RequestItemAttachments;
