import React, { useState } from 'react';
import Button from '../../components/Button/Button';
import InputText from '../../components/Input/InputText';
import { instanceFetch } from '../../utils/instanceFetch';
import { session } from '../../helpers/helpers';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

const ConfirmPassword = () => {
  const token = session.get('token');
  const [email, setEmail] = useState('');
  const [errorsInPost, setErrorsInPost] = useState([]);
  const [isSuccess, setIsSuccess] = useState('');
  const { push } = useHistory();

  const ROUTES = {
    PASSWORD: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/forgot-password`),
  };

  const onChange = (e) => {
    setEmail(e.target.value);
    setErrorsInPost([]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    instanceFetch
      .post(ROUTES.PASSWORD, { email: email }, token)
      .then((response) => {
        setErrorsInPost([]);
        setIsSuccess(response.message);
        // push('/restore-password');
      })
      .catch((err) => {
        setErrorsInPost(err.message);
      });
  };

  return (
    <div className="signin">
      <div className="signin__col signin__col_form">
        <a href="/" className="signin__link">
          <img src="/images/logo.svg" alt="logo" />
        </a>

        <div className="signin__inner">
          <div className="text-black-bold-f40-l48">Скинути пароль?</div>
          <div className="text-grey-dark-normal-f16-l28">
            Нагадайте ваш email, щоб ми надіслали на нього листа-інструкцію зі зміни паролю.
          </div>

          <form onSubmit={handleSubmit} className="form recovery-password-form">
            <InputText
              id="recovery-email"
              label="Email:"
              onChange={onChange}
              error={!isEmpty(errorsInPost) && errorsInPost}
            />
            <Button type="submit" text="Зберегти" bg="pink" block />

            {isSuccess && <div className="text-green-normal-f16-l28 ">{isSuccess}</div>}
          </form>
        </div>
      </div>

      <div className="signin__col signin__col_banner" />
    </div>
  );
};

export default ConfirmPassword;
