import React, {useContext, useEffect, useState} from 'react';
import {NavLink, useHistory} from "react-router-dom";
import Button from "../Button/Button";
import {AuthContext} from "../../Guard/AuthContext";
import {session} from "../../helpers/helpers";
import {instanceFetch} from "../../utils/instanceFetch";
import {ContactStoresSelect} from './ContactStoresSelect'

const Header = () => {
  const token = session.get('token');
  const {user} = useContext(AuthContext);
  const { push } = useHistory();
  const [shopName, setShopName] = useState('');

  const ROUTES = {
    LOCATIONS: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/location`),
    // BALANCE: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/user/balance`),
  };

  useEffect(() => {
    instanceFetch
      .get(ROUTES.LOCATIONS, null, token)
      .then((response) => setShopName(response.Name))
      .catch((error) => console.log(error));
    // instanceFetch.get(ROUTES.BALANCE, null, token).then((res) => !isEmpty(res) && setShopName(res[0].name)).catch((error) => {console.log(error)});
  }, []);


  const handleLogout = () => {
    session.remove('token');
    push('/signin')
  };

  return (
    <header className="header">
      <div className='header-left-side'>
        <ContactStoresSelect />
      </div>
      <div className='header-right-side'>
        <NavLink
          to='/profile'
          className='header-user'
        >
          <>
            <img className='header-user__avatar' src="/images/avatar.png" alt="avatar"/>
            <div className='text-black-normal-f16-l28'>{user?.email}</div>
          </>
        </NavLink>

        <Button
          className='header__logout'
          onClick={() => handleLogout()}
          text="Вийти"
          bg="turquoise"
        />
      </div>
    </header>
  );
};

export default Header;
