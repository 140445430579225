import {Form, Input} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useRequestContext} from './Request.Provider';
import {PrepareSelect} from './RequestCreate';

const exceptionCategory = [
  'aa5a0901-0ed4-4191-853e-16c33c7d3b57', // Ремонтні роботи
  'b204ab4c-6ca8-49e2-a1ef-68547c35818b', // ІТ роботи
  'd702ac89-b483-45d2-8d9f-91db02196f6f', // Регламентні роботи
  'ea5700f4-3499-4b19-b455-95210570fdb4', // Ремонт пожежної системи
  // '5369e6b8-4f16-45b3-8114-bdeeb2ca4969', // Маркетингові роботи
  'c43f2d00-b024-441a-8a74-a6a0e299020f', // Технічні неполадки
];

const repairsCategory = 'aa5a0901-0ed4-4191-853e-16c33c7d3b57';
export const contractingOrganizations = '648b6226-ae39-4385-a20b-95ebe260b68d'; //Підрядні організації
export const internalResourceShoppingCenter = '4857f213-f264-4cc4-99e2-a234e3cb3efb'; //Внутрішній ресурс ТРЦ

export const RequestJob = ({
  requests,
  formContentItem,
  PrepareSelect,
  prepareSelectOptions,
  disabledForm,
}) => {
  const { availableRequestFields, renderAviableFieldsToEdit, form } = useRequestContext();
  const [selectedCategory, setSelectedCategory] = useState(availableRequestFields?.Category?.value);
  const [selectedContractor, setSelectedContractor] = useState(availableRequestFields?.SuContractorType?.value);
  const [filteredSubServiceCategory, setFiltredSubServiceCategory] = useState([]);
  const filterCategoriesJob = requests?.ServiceCategory?.filter(({ Id }) => {
    return exceptionCategory.includes(Id);
  });

  useEffect(() => {
    if (availableRequestFields?.Category) {
      setSelectedCategory(availableRequestFields?.Category?.value);
      setSelectedContractor(availableRequestFields?.SuContractorType?.value);
    }
  }, [availableRequestFields]);

  useEffect(() => {
    if (!selectedCategory) {
      setSelectedCategory(availableRequestFields?.Category?.value);
    }
  }, [availableRequestFields?.Category])
  // const exceptionWork = [nightsWorksServiceSubCategory, dailiesWorksServiceSubCategory, dayWorksServiceSubCategory];

  const exceptionWork = [
    'fe05744e-092b-41b7-a98c-bd2764355fa0', //Загальнобудівельні
    'be524ea5-f6d9-4cd5-b9a0-ba7756d2dc39', //Заміна лампочки
    '418a26b7-fec7-4e55-8966-9ba481b3e390', //Ремонт вентиляції
    '0184a995-ee00-4487-b4d6-4fc5848601c5', // Підключення до системи вентиляції
    'bd289bea-e884-499e-9b1a-057f4d26044f', //Підключення електрики
    '64370769-1c44-41fe-8577-5c4c8c8c137d', //Жироуловлювачі
    '244a265f-02fb-4f93-b96c-07500cce6b11', //Вода-Каналізація
  ];
  React.useEffect(() => {
    if (selectedCategory === repairsCategory) {
      const worksOptions = requests.SuServiceSubCategory?.filter(({ Id }) =>
        exceptionWork.includes(Id)
      );

      return setFiltredSubServiceCategory(worksOptions);
    }
  }, [selectedCategory]);

  const SuServiceSubCategoryPermission = (required) => (
    <Form.Item
      name="SuServiceSubCategory"
      label="Підкатегорія сервісу"
      rules={[{ required: required, message: 'Вкажіть підкатегорію сервісу' }]}
    >
      <PrepareSelect
        defaultValue={availableRequestFields?.SuServiceSubCategory?.displayValue}
        options={prepareSelectOptions(filteredSubServiceCategory)}
        placeholder="Обрати"
        disabledForm={disabledForm}
      />
    </Form.Item>
  );

  const formPossibleFields = {
    RespNightWork: (
      <Form.Item
        name="RespNightWork"
        label="Виконавці робіт"
        rules={[{ required: true, message: 'Вкажіть виконавців робіт' }]}
      >
        <Input placeholder="Вкажіть виконавців робіт" disabled={disabledForm} />
      </Form.Item>
    ),
    RespContractorTenantResonsible: (
      <Form.Item
        name="RespContractorTenantResonsible"
        label="Відповідальна особа підрядної організації"
        rules={[{ required: true, message: 'Вкажіть відповідальну особу підрядної організації' }]}
      >
        <Input
          placeholder="Вкажіть відповідальну особу підрядної організації"
          disabled={disabledForm}
        />
      </Form.Item>
    ),
    RespContractorTelNumberTenant: (
      <Form.Item
        name="RespContractorTelNumberTenant"
        label="Телефон відповідального підрядної організації"
        rules={[
          { required: true, message: 'Вкажіть телефон відповідального підрядної організації' },
        ]}
      >
        <Input
          placeholder="Вкажіть телефон відповідального підрядної організації"
          disabled={disabledForm}
        />
      </Form.Item>
    ),
    RespContractorName: (
      <Form.Item
        name="RespContractorName"
        label="Назва підрядної організації"
        rules={[{ required: true, message: 'Вкажіть назву підрядної організації' }]}
      >
        <Input placeholder="Вкажіть назву підрядної організації" disabled={disabledForm} />
      </Form.Item>
    ),
    SuWorkDescription: (
      <Form.Item
        name="SuWorkDescription"
        label="Опис робіт"
        rules={[{ required: true, message: 'Вкажіть опис робіт' }]}
      >
        <Input placeholder="Якого характеру роботи" disabled={disabledForm} />
      </Form.Item>
    ),
    SuContractorType: (
      <Form.Item
        name="SuContractorType"
        label="Виконавець робіт"
        rules={[{ required: true, message: 'Вкажіть виконавця робіт' }]}
      >
        <PrepareSelect
          defaultValue={availableRequestFields?.SuContractorType?.displayValue}
          options={prepareSelectOptions(requests.SuCaseContractorType)}
          placeholder="Обрати"
          onChange={(selectedContractor) => setSelectedContractor(selectedContractor)}
          disabledForm={disabledForm || availableRequestFields?.SuContractorType?.displayValue}
        />
      </Form.Item>
    ),
    Symptoms: (
      <Form.Item
        name="Symptoms"
        label="Коментарі до запиту"
        rules={[{ required: true, message: 'Вкажіть коментарі до запиту' }]}
      >
        <TextArea
          placeholder="Що плануєте завезти/вивезти (приблизну кількість і для чого)."
          disabled={disabledForm}
        />
      </Form.Item>
    ),
    SuServiceSubCategoryPermission: SuServiceSubCategoryPermission(true),
    SuServiceSubCategoryWithoutPermission: SuServiceSubCategoryPermission(false),
  };

  const isEditable = availableRequestFields?.Category?.value;
  
  // if (isEditable) {
  //   return renderAviableFieldsToEdit(formPossibleFields);
  // }
  
  const currentCategoryValue = form.getFieldValue('Category');
  const currentServiceCategory = form.getFieldValue('ServiceCategory');
  
  return (
    <>
      {!isEditable && (
        <Form.Item
          name="ServiceCategory"
          label="Категорія сервісу"
          rules={[{ required: true, message: 'Вкажіть категорію сервісу' }]}
        >
          <PrepareSelect
            options={prepareSelectOptions(filterCategoriesJob)}
            placeholder="Обрати"
            onChange={(selectedCategory) => setSelectedCategory(selectedCategory)}
            disabledForm={disabledForm}
          />
        </Form.Item>
      )}

      {availableRequestFields?.SuStartWork &&
        formContentItem.SuWork(availableRequestFields?.SuStartWork ?? moment(), availableRequestFields?.SuEndWork ?? moment().add(+1, 'hours'))}
      {currentCategoryValue
        && currentServiceCategory
        && currentServiceCategory !== repairsCategory
        && formContentItem.SuWork(moment(), moment().add(+1,'hours'))
      }
      {(selectedCategory === repairsCategory) && (
        <>
          {formPossibleFields['SuContractorType']}
          {selectedContractor && formContentItem.SuWork(moment(), moment().add(+1,'hours'))}
          {selectedContractor === contractingOrganizations && (
            <>
              {!!filteredSubServiceCategory?.length &&
                formPossibleFields['SuServiceSubCategoryWithoutPermission']}
              {formContentItem.SuTenantResonsible}
              {formContentItem.SuTelNumberTenant}
              {formPossibleFields['RespContractorName']}
              {formPossibleFields['RespNightWork']}
              {formPossibleFields['RespContractorTenantResonsible']}
              {formPossibleFields['RespContractorTelNumberTenant']}
              {formContentItem.SuLoadingZone}
              {formContentItem.SuCarNumber}
              {formContentItem.MarketingFile}
              {formPossibleFields['Symptoms']}
              {formPossibleFields['SuWorkDescription']}
            </>
          )}
          {(selectedContractor === internalResourceShoppingCenter) && (
            <>
              {!!filteredSubServiceCategory?.length &&
                formPossibleFields['SuServiceSubCategoryPermission']}
              {formContentItem.SuTenantResonsible}
              {formContentItem.SuTelNumberTenant}
              {formContentItem.Symptoms}
              {formContentItem.SuWorkDescription}
            </>
          )}
        </>
      )}

      {((selectedCategory && selectedCategory !== repairsCategory)) && (
        <>
          {selectedCategory === '5369e6b8-4f16-45b3-8114-bdeeb2ca4969' &&
            formContentItem.MarketingFile
          }
          {formContentItem.Symptoms}
          <Form.Item name="SuWorkDescription" label="Опис робіт">
            <Input placeholder="Вкажіть опис робіт" disabled={disabledForm} />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default RequestJob;
