import { session } from "../../helpers/helpers";
import moment from "moment";

export const ROUTES = {
  GET_REQUESTS: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests/references`),
  POST_REQUESTS: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests`),
};

export const token = session.get('token');
export const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
