import React from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import cx from 'classnames';

const RequestItemCommentList = ({ request }) => {
  return (
    <div className="request-history-list">
      {isEmpty(request.Messages)
        ? 'Повідомлень немає'
        : request.Messages.map(({ Message, CreatedOn, SuRemoteCreatedBy }) => {
          const author = SuRemoteCreatedBy ? SuRemoteCreatedBy.displayValue : 'Адміністратор';
          const date = moment(CreatedOn).format('YYYY-MM-DD');
          const requestHeaderClass = cx('request-history-item-header', {admin: SuRemoteCreatedBy});
          return (
            <div className="request-history-item" key={CreatedOn}>
              <div className={requestHeaderClass}>
                <div className="text-black-medium-f18-l28">{author}</div>
                {/*<div className="request-history-item-status red">{status}</div>*/}
                <div className="text-grey-normal-f16-l28">{date}</div>
              </div>
              <div className="request-history-item-message text-black-normal-f16-l28">
                {Message}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default RequestItemCommentList;
