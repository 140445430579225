import React, { useEffect, useState } from 'react';
import { session } from '../../helpers/helpers';
import { instanceFetch } from '../../utils/instanceFetch';
import Button from '../../components/Button/Button';
import {Form} from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const RequestItemLeaveComment = ({ id, setRequest }) => {
  const [currentValue, setCurrentValue] = useState('');
  const [submittedValue, setSubmittedValue] = useState('');
  const [clickedSubmit, setClickedSubmit] = useState(false);

  const token = session.get('token');
  const formRef = React.useRef(null);
  const [form] = Form.useForm();

  const ROUTES = {
    REQUEST: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests/${id}`),
    MESSAGE: new URL(`${process.env.REACT_APP_API_URL}/api/b2b/requests/${id}/message`),
  };

  useEffect(() => {
    instanceFetch.get(ROUTES.REQUEST, null, token).then((request) => {
      setRequest(request);
    });
  }, [submittedValue]);

  const handleSubmit = () => {
    setClickedSubmit(true);
    instanceFetch
      .post(ROUTES.MESSAGE, { message: currentValue }, token)
      .then(() => {
        if (currentValue) {
          setSubmittedValue(currentValue);
          setCurrentValue('');
          form.resetFields();
          setClickedSubmit(false)
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const onChange = (e) => {
    setCurrentValue(e.target.value);
  };

  return (
    <div className="box request-leave-comment">
      <div className="text-black-medium-f22-l30">Залишити коментар</div>
      <Form form={form} ref={formRef}>
        <Form.Item name="Subject" rules={[{ required: true, message: 'Введіть текст' }]}>
          <TextArea rows={4} placeholder="Введіть текст" onChange={onChange} />
        </Form.Item>
        <Form.Item>
          <Button onClick={handleSubmit} type="submit" text="Надіслати" bg="pink" disabled={clickedSubmit}/>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RequestItemLeaveComment;
