import { Form } from 'antd';
import React from 'react';

const exceptionDepartment = [
  '9b90c192-60e6-df11-971b-001d60e938c6', // Розробка
  '2076c4b6-7fe6-df11-971b-001d60e938c6', // Продажі
  '66ffa487-b4da-df11-9b2a-001d60e938c6', // Адміністрація
  '4691a98e-b4da-df11-9b2a-001d60e938c6', // Логістика
  '4791a98e-b4da-df11-9b2a-001d60e938c6', // Маркетинг
  '769964a0-b4da-df11-9b2a-001d60e938c6', // Виробництво
  '2a350a9b-9298-4f08-a24a-19a37e6acbaf', // Експлутаційно - технічний департамент
  '3abe9e81-c2fa-4f0c-993c-1f63655ad118', // Бухгалтерія
];

export const RequestOthers = ({
  formContentItem,
  requests,
  PrepareSelect,
  prepareSelectOptions,
  disabledForm,
}) => {
  const filterDepartment = requests?.SuClaimDepartment?.filter(({ Id }) => {
    return exceptionDepartment.includes(Id);
  });

  return (
    <>
      <Form.Item
        name="ServiceCategory"
        label="Категорія сервісу"
        rules={[{ required: true, message: 'Вкажіть категорію сервісу' }]}
      >
        <PrepareSelect
          options={prepareSelectOptions(requests.ServiceCategory)}
          placeholder="Обрати"
          disabledForm={disabledForm}
        />
      </Form.Item>

      <Form.Item
        name="SuClaimDepartment"
        label="Департамент"
        rules={[{ required: true, message: 'Вкажіть департамент' }]}
      >
        <PrepareSelect
          options={prepareSelectOptions(filterDepartment)}
          placeholder="Обрати"
          disabledForm={disabledForm}
        />
      </Form.Item>

      {formContentItem.Symptoms}
    </>
  );
};

export default RequestOthers;
