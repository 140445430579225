export const getBlobFromUrl = (myImageUrl, token) => {
  return new Promise((resolve, reject) => {
    fetch(myImageUrl, { method: 'GET', headers: { Authorization: 'Bearer ' + token } })
      .then((response) => {
        response.blob().then((data) => {
          resolve(data);
        });
      })
      .catch((err) => {
        console.log('Fetch Error', err);
        reject(err);
      });
  });
};

export const getDataFromBlob = async (myBlob, filename) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      try {
        const result = fileReader.result;
        resolve(result);

        const contentType = myBlob.type;

        const attachmentFileContainer = document.getElementsByClassName('request-attachment-file')[0];
        if (attachmentFileContainer) {
        if (contentType === 'application/msword' || contentType === 'application/pdf') {
          const a = document.createElement('a');
          a.href = result;
          a.download = filename;
          a.textContent = filename;
          a.classList.add('f16-black');
          attachmentFileContainer.appendChild(a);
        } else if (contentType === 'image/jpeg' || contentType === 'image/png') {
          const img = document.getElementsByClassName('request-attachment-img')[0]
          img.src = result;
          attachmentFileContainer.appendChild(img);
        } else {
          console.log('Unsupported file type');
        }
        } else {
          console.log('Attachment file container not found');
        }

      } catch (err) {
        reject(err);
      }
    };

    fileReader.onerror = () => {
      reject(new Error('Error reading the file'));
    };

    fileReader.readAsDataURL(myBlob);
  });
};

const downloadFile = async (imageURL, token) => {
  try {
    const blob = await getBlobFromUrl(imageURL, token);
    const imageData = await getDataFromBlob(blob);
    const a = document.createElement('a');
    a.href = imageData;
    a.download = 'receipt';
    a.click();
    return imageData;
  } catch (err) {
    console.log(err);
    return null;
  }
};
export { downloadFile };
