import SelectComponent from '../Select/Select'
import {useContactStore} from "../../hooks/useContactStore";

export const ContactStoresSelect = () => {
    const {currentContactStore, handleChange, currentContactStoreOptions} = useContactStore();
    
    return <SelectComponent
        options={currentContactStoreOptions}
        value={currentContactStore?.name}
        onChange={(e) => handleChange(e)}
        disabled={currentContactStoreOptions?.length < 2}
    />;
};