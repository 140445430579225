import Accounts from '../pages/Accounts/Accounts';
import Commodity from '../pages/Commodity/Commodity';
import Dashboard from '../pages/Dashboard/Dashboard';
import Profile from '../pages/Profile/Profile';
import ChangePassword from '../pages/Signin/ChangePassword';
import RecoveryPassword from '../pages/Signin/RecoveryPassword';
import Signin from '../pages/Signin/Signin';
import RequestCreateContainer from '../pages/Support/RequestCreateContainer';
import RequestEditEntry from '../pages/Support/RequestEditEntry';
import RequestItem from '../pages/Support/RequestItem';
import RequestList from '../pages/Support/RequestList';

const mainRoutes = [
  {
    path: '/',
    exact: true,
    component: Dashboard,
  },
  {
    path: '/signin',
    exact: true,
    component: Signin,
  },
  {
    path: '/recovery-password',
    exact: true,
    component: RecoveryPassword,
  },
  {
    path: '/restore-password',
    exact: true,
    component: ChangePassword,
  },
  {
    path: '/requests',
    exact: true,
    component: RequestList,
  },
  {
    path: '/create-request',
    component: RequestCreateContainer,
    exact: true,
  },
  {
    path: '/edit-request/:id',
    component: RequestEditEntry,
    exact: true,
  },
  {
    path: '/requests/:id',
    component: RequestItem,
    exact: true,
  },
  // {
  //   path: '/marketing',
  //   component: Marketing,
  //   exact: true,
  // },
  {
    path: '/profile',
    exact: true,
    component: Profile,
  },
  {
    path: '/accounts',
    exact: true,
    component: Accounts,
  },
  {
    path: '/commodity-circulation',
    exact: true,
    component: Commodity,
  },
  // {
  //   path: '/stock',
  //   exact: true,
  //   component: Stock,
  // },
];

export default mainRoutes;
