import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const SelectComponent = (props) => {
  const { options, labelText, placeholder, id, error, prompt, mode, value, onChange, disabled } = props;

  return (
    <div className="field field_col">
      {labelText && (
        <label className="field__label" htmlFor={id}>
          {labelText}
        </label>
      )}

      <Select
        mode={mode && mode}
        placeholder={placeholder}
        className="field__select"
        id={id}
        onChange={onChange}
        value={value}
        loading={!options?.length}
        disabled={disabled}
      >
        {options?.map(({ value, text }) => (
          <Option key={value}>{text}</Option>
        ))}
      </Select>

      {!error && prompt && <div className="field__prompt">{prompt}</div>}
      {error && <div className="field__error">{error}</div>}
    </div>
  );
};

export default SelectComponent;
